<template>
    <div class="seemore-view">
        <span 
            class="p_des"
            v-if="des.length<200"
        > 
            {{des}}
        </span>
        <div class="" v-else>
            <span 
                class="p_des"
                v-if="!readMoreActivated"
            > 
                {{ des.slice(0, 200) }}...
                <a class="read_more" @click="activateReadMore(true)">view more</a>
            </span>
            <p 
                class="p_des" 
                v-if="readMoreActivated"
            >
                {{des}} <a class="read_more" @click="activateReadMore(false)">view less</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SeeMore',
    props: {
        des: {
            type: String
        }
    },
    data() {
        return{
            readMoreActivated: false
        }
    },
    methods: {
        activateReadMore(bool){
            this.readMoreActivated = bool
        }
    }
}
</script>

<style lang="scss">
@import './SeeMore.scss';
</style>