<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="home-affiliate-view">
            <div class="head-display">
                <div class="display-image">
                    <div class="img"></div>
                    <div class="display-info">
                        We Will Help You Create Your Perfect Space
                    </div>
                </div>
            </div>
            <div class="affiliate-view-body">
                <div
                    class="product-info"
                    v-for="(merch, index) in affiliate_details.payloads"
                    :key="merch + '_' + index"
                >
                    <div class="products">
                        <div class="p-img">
                            <router-link
                                :to="{
                                    name: 'AffiliatePage',
                                    query: { link: merch.link },
                                }"
                            >
                                <img
                                    v-if="merch.image"
                                    :src="merch.image"
                                    :alt="merch.title"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/default-image.png"
                                />
                            </router-link>
                        </div>
                        <div class="p-info">
                            <div class="">
                                <router-link
                                    class="info-head"
                                    :to="{
                                        name: 'AffiliatePage',
                                        query: { link: merch.link },
                                    }"
                                >
                                    {{ merch.title }}
                                </router-link>
                            </div>
                            <SeeMore class="p_des" :des="merch.description" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import AffiliatePage from 'store/modules/AffiliatePage'

import SeeMore from 'componentsv2/SeeMore'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'AffiliateHomePage',
    components: {
        SeeMore,
        Footer,
        Loader,
    },
    mixins: [RegisterStoreModule, loaderHandler],
    computed: {
        ...mapState({
            affiliate_details: state => state.AffiliatePage.affiliate_details,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: `%s | Affiliate`,
        bodyAttrs: {
            class: ['affiliated-page'],
        },
    },
    created() {
        this.registerStoreModule('AffiliatePage', AffiliatePage)
        this.fetchAffiliateDetails()
            .then(() => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 100)
            })
    },
    destroyed() {
        // this.$store.unregisterModule('AffiliatePage')
    },
    methods: {
        ...mapActions({
            fetchAffiliateDetails: 'fetchAffiliateDetails',
        }),
    },
}
</script>

<style lang="scss">
@import 'AffiliateHomePage.scss';
</style>
